import React from "react";
import axios from "axios";
import toast from "react-hot-toast";
import CardBody from "../../common/CardBody";
import ViewButton from "../../common/ViewButton";
import DeleteButton from "../../common/DeleteButton";
import { Dropdown, Form } from "react-bootstrap";
import EditButton from "../../common/EditButton";
import { convertAmount, convertFloat } from "../../../data/durations";
import { MoreIcon } from "../../../icons";

const AgentListBox = ({
      loading,
      agents,
      totalAgents,
      perPages,
      setLimit,
      currentPage,
      getData,
      fetchAgents,
      setSearch,
      setAgents,
      setLoading,
}) => {
      const token = JSON.parse(localStorage.getItem("token"));

      const handleStatus = async (category) => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/airlines/${category.slug}/status`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === "error") {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const handleDelete = async (blog) => {
            const response = await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/airlines/${blog.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setAgents((agents) =>
                                    agents.filter((item) => item.id !== blog.id)
                              );
                              fetchAgents();
                              toast.success(response.data.message);
                              return true;
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            return response;
      };

      return (
            <>
                  <CardBody
                        totalItems={totalAgents}
                        perPages={perPages}
                        setLimit={setLimit}
                        currentPage={currentPage}
                        getData={getData}
                        setSearch={setSearch}
                        border={true}
                        borderStyle={true}
                  >
                        <thead>
                              <tr>
                                    <th width="50px">#</th>
                                    <th>Name</th>
                                    <th>Company Name</th>
                                    <th>Apply On</th>
                                    <th>Contact</th>
                                    <th>Balance (INR)</th>
                                    <th width="100px">Status</th>
                                    <th width="140px">Action</th>
                              </tr>
                        </thead>

                        <tbody>
                              {!loading ? (
                                    agents.length > 0 ? (
                                          agents.map((blog, index) => (
                                                <>
                                                      <tr key={index}>
                                                            <td>
                                                                  {blog.index}
                                                            </td>
                                                            <td>{blog.name}</td>
                                                            <td>
                                                                  {
                                                                        blog.company_name
                                                                  }
                                                            </td>
                                                            <td>{blog.date}</td>
                                                            <td>
                                                                  {
                                                                        blog?.phone_number
                                                                  }
                                                            </td>
                                                            <td>
                                                                  {blog?.balance
                                                                        ? convertAmount(
                                                                                convertFloat(
                                                                                      blog?.balance
                                                                                )
                                                                          )
                                                                        : 0}
                                                            </td>
                                                            <td className="text-center">
                                                                  <Form.Check
                                                                        type="switch"
                                                                        id="custom-switch"
                                                                        defaultChecked={
                                                                              blog.status ===
                                                                              "Approved"
                                                                                    ? true
                                                                                    : false
                                                                        }
                                                                        onClick={() =>
                                                                              handleStatus(
                                                                                    blog
                                                                              )
                                                                        }
                                                                  />
                                                            </td>
                                                            <td className="text-center">
                                                                  <Dropdown>
                                                                        <Dropdown.Toggle
                                                                              id="dropdown-basic"
                                                                              as={
                                                                                    "div"
                                                                              }
                                                                        >
                                                                              <MoreIcon />
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                              <Dropdown.Item as="div">
                                                                                    <ViewButton
                                                                                          title="View
                                                                                    Agent"
                                                                                          link={`/dashboard/agents/${blog.slug}/show`}
                                                                                    />{" "}
                                                                              </Dropdown.Item>
                                                                              <Dropdown.Item
                                                                                    as={
                                                                                          "div"
                                                                                    }
                                                                              >
                                                                                    <EditButton
                                                                                          link={`/dashboard/agents/${blog.slug}/commission`}
                                                                                          title="Edit Commission"
                                                                                          classes="mx-2"
                                                                                    />
                                                                              </Dropdown.Item>
                                                                              <Dropdown.Item as="div">
                                                                                    <DeleteButton
                                                                                          item={
                                                                                                blog
                                                                                          }
                                                                                          loading={
                                                                                                loading
                                                                                          }
                                                                                          setLoading={
                                                                                                setLoading
                                                                                          }
                                                                                          handleDelete={
                                                                                                handleDelete
                                                                                          }
                                                                                          classes="mx-2"
                                                                                          title="Delete
                                                                                    Agent"
                                                                                          />
                                                                              </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                  </Dropdown>
                                                                  {/* <TopupButton /> */}
                                                            </td>
                                                      </tr>
                                                </>
                                          ))
                                    ) : (
                                          <>
                                                <tr>
                                                      <td
                                                            colSpan={7}
                                                            className="text-center"
                                                      >
                                                            No Request Found
                                                      </td>
                                                </tr>
                                          </>
                                    )
                              ) : (
                                    <>
                                          <tr>
                                                <td
                                                      colSpan={7}
                                                      className="text-center"
                                                >
                                                      Loading...
                                                </td>
                                          </tr>
                                    </>
                              )}
                        </tbody>
                  </CardBody>
            </>
      );
};

export default AgentListBox;
