import React from "react";
import axios from "axios";
import toast from "react-hot-toast";
import CardBody from "../../common/CardBody";
import ViewButton from "../../common/ViewButton";
import DeleteButton from "../../common/DeleteButton";
import EditButton from "../../common/EditButton";
import ProductImage from "../../common/ProductImage";

const AirlineListBox = ({
      loading,
      agents,
      totalAgents,
      perPages,
      setLimit,
      currentPage,
      getData,
      fetchAgents,
      setSearch,
      setAgents,
      setLoading,
}) => {
      const token = JSON.parse(localStorage.getItem("token"));

      const handleStatus = async (category) => {
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/airlines/${category.slug}/status`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === "error") {
                              toast.error(response.data.message);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
      };

      const handleDelete = async (blog) => {
            const response = await axios
                  .delete(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/airlines/${blog.slug}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setAgents((agents) =>
                                    agents.filter((item) => item.id !== blog.id)
                              );
                              fetchAgents();
                              toast.success(response.data.message);
                              return true;
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            return response;
      };

      return (
            <>
                  <CardBody
                        totalItems={totalAgents}
                        perPages={perPages}
                        setLimit={setLimit}
                        currentPage={currentPage}
                        getData={getData}
                        setSearch={setSearch}
                        border={true}
                        borderStyle={true}
                  >
                        <thead>
                              <tr>
                                    <th width="50px">#</th>
                                    <th className="text-start">Name</th>
                                    {/* <th width="100px">Status</th> */}
                                    <th width="140px">Action</th>
                              </tr>
                        </thead>

                        <tbody>
                              {!loading ? (
                                    agents.length > 0 ? (
                                          agents.map((blog, index) => (
                                                <>
                                                      <tr key={index}>
                                                            <td>
                                                                  {blog.index}
                                                            </td>
                                                            <td className="text-start">
                                                                  <ProductImage
                                                                        title={
                                                                              blog.title
                                                                        }
                                                                        image={
                                                                              blog?.image
                                                                        }
                                                                        category={
                                                                              blog?.code
                                                                        }
                                                                  />
                                                            </td>
                                                            {/* <td className="text-center">
                                                                  <Form.Check
                                                                        type="switch"
                                                                        id="custom-switch"
                                                                        defaultChecked={
                                                                              blog.status ===
                                                                              "Approved"
                                                                                    ? true
                                                                                    : false
                                                                        }
                                                                        onClick={() =>
                                                                              handleStatus(
                                                                                    blog
                                                                              )
                                                                        }
                                                                  />
                                                            </td> */}
                                                            <td className="text-center">
                                                                  {/* <TopupButton /> */}
                                                                  <EditButton
                                                                        link={`/dashboard/airlines/${blog.slug}/edit`}
                                                                  />
                                                                  <ViewButton
                                                                        link={`/dashboard/airlines/${blog.slug}/show`}
                                                                  />
                                                                  <DeleteButton
                                                                        item={
                                                                              blog
                                                                        }
                                                                        loading={
                                                                              loading
                                                                        }
                                                                        setLoading={
                                                                              setLoading
                                                                        }
                                                                        handleDelete={
                                                                              handleDelete
                                                                        }
                                                                  />
                                                            </td>
                                                      </tr>
                                                </>
                                          ))
                                    ) : (
                                          <>
                                                <tr>
                                                      <td
                                                            colSpan={7}
                                                            className="text-center"
                                                      >
                                                            No Request Found
                                                      </td>
                                                </tr>
                                          </>
                                    )
                              ) : (
                                    <>
                                          <tr>
                                                <td
                                                      colSpan={7}
                                                      className="text-center"
                                                >
                                                      Loading...
                                                </td>
                                          </tr>
                                    </>
                              )}
                        </tbody>
                  </CardBody>
            </>
      );
};

export default AirlineListBox;
