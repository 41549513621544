import React from "react";

const MoreIcon = ({ width, height, color }) => {
      return (
            <>
                  <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height={height || "24px"}
                        viewBox="0 -960 960 960"
                        width={width || "24px"}
                        fill={color || "#333"}
                  >
                        <path d="M480-160q-33 0-56.5-23.5T400-240q0-33 23.5-56.5T480-320q33 0 56.5 23.5T560-240q0 33-23.5 56.5T480-160Zm0-240q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-240q-33 0-56.5-23.5T400-720q0-33 23.5-56.5T480-800q33 0 56.5 23.5T560-720q0 33-23.5 56.5T480-640Z" />
                  </svg>
            </>
      );
};

export default MoreIcon;
