import { Route } from "react-router-dom";
import { AgentRequestList, AgentRequestShow } from "../backend/agent_requests";
import { AgentList, AgentShow } from "../backend/agents";
import AgentCommissionEdit from "../backend/agents/AgentCommissionEdit";
import HotelCommissionPage from "../backend/hotel_commissions";

export const agentManages = [
      // Agent
      <Route path="/dashboard/agents" element={<AgentList />} />,
      <Route path="/dashboard/agents/:agentId/show" element={<AgentShow />} />,

      // Commission
      <Route
            path="/dashboard/agents/:agentId/commission"
            element={<AgentCommissionEdit />}
      />,

      // Hotel Commission
      <Route
            path="/dashboard/hotel_commissions/:agentId/commission"
            element={<HotelCommissionPage />}
      />,

      // Agent Request
      <Route path="/dashboard/agent_requests" element={<AgentRequestList />} />,
      <Route
            path="/dashboard/agent_requests/:agentId/show"
            element={<AgentRequestShow />}
      />,
];
