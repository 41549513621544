import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import CardHeader from "../../components/common/CardHeader";
import LinkButton from "../../components/common/LinkButton";
import { ItemShow } from "../../components/form";
import toast from "react-hot-toast";
import RejectModal from "../agent_requests/RejectModal";
import EditButton from "../../components/common/EditButton";

const AgentShow = () => {
      const { agentId } = useParams();
      const [agent, setAgent] = useState([]);
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/agent_requests/${agentId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem("token")
                                    )}`,
                                    "Content-Type": "multipart/form-data",
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setAgent(response.data.agent);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const [show, setShow] = useState(false);
      const [loadingForm, setLoadingForm] = useState(false);
      const [loadingModal, setLoadingModal] = useState(false);

      const handleAccept = async () => {
            setLoadingForm(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/agent_requests/${agentId}/approve`,
                        {
                              headers: {
                                    Authorization: `Bearer ${JSON.parse(
                                          localStorage.getItem("token")
                                    )}`,
                              },
                        }
                  )
                  .then((response) => {
                        setTimeout(() => {
                              if (response.data.result === "success") {
                                    toast.success(response.data.message);
                                    setAgent({
                                          ...agent,
                                          ["status"]: "Approved",
                                    });
                              }
                              setLoadingForm(false);
                        }, 3000);
                  })
                  .catch((error) => {
                        setTimeout(() => {
                              setLoadingForm(false);
                              toast.error(error.message);
                        }, 3000);
                  });
      };

      const handleReject = () => {
            setLoadingModal(true);
            setTimeout(() => {
                  setShow(true);
                  setLoadingModal(false);
            }, 3000);
      };

      return (
            <>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Agent Detail">
                                          {agent.status !== "Approved" && (
                                                <LinkButton
                                                      link="#"
                                                      title="Accept"
                                                      width="90px"
                                                      loading={loadingForm}
                                                      handleClick={handleAccept}
                                                />
                                          )}
                                          <LinkButton
                                                link="#"
                                                title="Reject"
                                                width="84px"
                                                loading={loadingModal}
                                                handleClick={handleReject}
                                          />
                                          <LinkButton
                                                title="Back"
                                                link="/dashboard/agents"
                                          />
                                    </CardHeader>
                                    <Card.Body>
                                          {!loading ? (
                                                <Row>
                                                      <Col lg={5}>
                                                            <h4>
                                                                  Personal
                                                                  details
                                                            </h4>
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title="Title"
                                                                        value={
                                                                              agent
                                                                                    ?.personal
                                                                                    ?.name
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Gender"
                                                                        value={
                                                                              agent
                                                                                    ?.personal
                                                                                    ?.gender
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Date of Birth"
                                                                        value={
                                                                              agent
                                                                                    ?.personal
                                                                                    ?.date_of_birth
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Email Address"
                                                                        value={
                                                                              agent
                                                                                    ?.personal
                                                                                    ?.email
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Phone No."
                                                                        value={
                                                                              agent
                                                                                    ?.personal
                                                                                    ?.phone_number
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Mobile No."
                                                                        value={
                                                                              agent
                                                                                    ?.personal
                                                                                    ?.mobile_number
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Alternative Mobile No."
                                                                        value={
                                                                              agent
                                                                                    ?.personal
                                                                                    ?.alternative_mobile_number
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Date"
                                                                        value={
                                                                              agent.date
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Status"
                                                                        value={
                                                                              agent.status
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Created By"
                                                                        value={
                                                                              agent.user_name
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Remarks"
                                                                        value={
                                                                              agent.note
                                                                        }
                                                                  />
                                                            </ListGroup>
                                                            <div className="mt-4">
                                                                  <EditButton
                                                                        link={`/dashboard/agents/${agent.slug}/commission`}
                                                                        title="Flight Commission"
                                                                        classes="btn  mx-2"
                                                                  />
                                                                  <EditButton
                                                                        link={`/dashboard/hotel_commissions/${agent.slug}/commission`}
                                                                        title="Hotel Commission"
                                                                        classes="btn  mx-2"
                                                                  />
                                                            </div>
                                                      </Col>
                                                      <Col lg={7}>
                                                            <h4>
                                                                  Company
                                                                  details
                                                            </h4>
                                                            <ListGroup>
                                                                  <ItemShow
                                                                        title="Company name"
                                                                        value={
                                                                              agent
                                                                                    ?.company
                                                                                    ?.company_name
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Owner type"
                                                                        value={
                                                                              agent
                                                                                    ?.company
                                                                                    ?.owner_type
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Office address"
                                                                        value={
                                                                              agent
                                                                                    ?.company
                                                                                    ?.office_address
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="PAN No."
                                                                        value={
                                                                              agent
                                                                                    ?.company
                                                                                    ?.pan_no
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Country"
                                                                        value={
                                                                              agent
                                                                                    ?.company
                                                                                    ?.country
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="State"
                                                                        value={
                                                                              agent
                                                                                    ?.company
                                                                                    ?.state
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="City"
                                                                        value={
                                                                              agent
                                                                                    ?.company
                                                                                    ?.city
                                                                        }
                                                                  />
                                                                  <ItemShow
                                                                        title="Address proof type"
                                                                        value={
                                                                              agent
                                                                                    ?.company
                                                                                    ?.address_proof_type
                                                                        }
                                                                  />
                                                            </ListGroup>
                                                      </Col>
                                                </Row>
                                          ) : (
                                                <div className="text-center">
                                                      <Spinner />
                                                </div>
                                          )}
                                    </Card.Body>
                              </Card>
                        </Col>
                  </Row>
                  <RejectModal
                        show={show}
                        slug={agentId}
                        agent={agent}
                        setAgent={setAgent}
                        handleClose={() => setShow(false)}
                  />
            </>
      );
};

export default AgentShow;
