import React from "react";
import { Link } from "react-router-dom";
import { ShowIcon } from "../../icons";

const ViewButton = ({ link, classes = '', title }) => {
      return (
            <>
                  <Link
                        to={link}
                        className={`${classes} text-success mx-2 d-inline-flex align-items-center gap-2`}
                  >
                        <ShowIcon />
                        {title || title}
                  </Link>
            </>
      );
};

export default ViewButton;
