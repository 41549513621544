import React from "react";
import { Col, Form } from "react-bootstrap";

const FormInput = ({ classes, title, value, handleChange, required, type }) => {
      return (
            <>
                  <Col lg={classes ? classes : 6}>
                        <Form.Label>
                              {title}{" "}
                              {required && (
                                    <span className="text-danger">*</span>
                              )}
                        </Form.Label>
                        <Form.Control
                              defaultValue={value}
                              onChange={(event) =>
                                    handleChange(event.target.value)
                              }
                              type={type ? type : "text"}
                        />
                  </Col>
            </>
      );
};

export default FormInput;
