import React, { useState, useEffect } from "react";
import ProfileLayout from "../ProfileLayout";
import ProfileTitle from "../../components/profile/ProfileTitle";
import { Form } from "react-bootstrap";
import axios from "axios";
import toast from "react-hot-toast";
import {
      FormButton,
      FormImage,
      FormInput,
} from "../../components/form";

function Profile() {
      const [loading, setLoading] = useState(false);
      const [loadingData, setLoadingData] = useState(false);
      const [image, setImage] = useState("");
      const [imageUrl, setImageUrl] = useState("");
      const [name, setName] = useState("");
      const [email, setEmail] = useState("");
      const [phoneNumber, setPhoneNumber] = useState("");
      const token = JSON.parse(localStorage.getItem("token"));

      const loadData = async () => {
            setLoadingData(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/dashboard/profile/update`, {
                        headers: {
                              Authorization: `Bearer ${token}`,
                        },
                  })
                  .then((response) => {
                        setName(response.data.user.name);
                        setEmail(response.data.user.email);
                        setImageUrl(response.data.user.signature);
                        setPhoneNumber(response.data.user.phone_number);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleFormSubmit = async (event) => {
            setLoading(true);
            event.preventDefault();

            const data = {
                  name: name,
                  image: image,
                  email: email,
                  phone_number: phoneNumber,
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/profile/update`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    "Content-Type": "multipart/form-data",
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                        }
                        if (response.data.result == "error") {
                              if (response.data.message.name) {
                                    toast.error(response.data.message.name);
                              }
                              if (response.data.message.email) {
                                    toast.error(response.data.message.email);
                              }
                              if (response.data.message.image) {
                                    toast.error(response.data.message.image);
                              }
                              if (response.data.message.phone_number) {
                                    toast.error(
                                          response.data.message.phone_number
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <ProfileLayout>
                        <ProfileTitle title="Personal Information" />

                        <Form className="row gap-3" onSubmit={handleFormSubmit}>
                              <FormInput
                                    title="Full Name"
                                    value={name}
                                    handleChange={setName}
                                    required={true}
                              />
                              <FormInput
                                    title="Email Address"
                                    value={email}
                                    handleChange={setEmail}
                                    required={true}
                              />
                              <FormInput
                                    title="Phone No."
                                    handleChange={setPhoneNumber}
                                    value={phoneNumber}
                                    required={true}
                              />
                              <div className="col-md-12">
                                    <FormImage
                                          title="Profile Image"
                                          setImageUrl={setImageUrl}
                                          setImage={setImage}
                                          imageUrl={imageUrl}
                                          grid={1}
                                    />
                              </div>
                              <div className="col-md-12">
                                    <FormButton
                                          loading={loading}
                                          title="Update"
                                    />
                              </div>
                        </Form>
                  </ProfileLayout>
            </>
      );
}

export default Profile;
