import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { CommissionItem } from "../agents/style";
import { FormInput, FormSelect } from "../../components/form";

const HotelCommissionPage = () => {
      const { agentId } = useParams();
      const [resetLoading, setResetLoading] = useState(false);

      const [loading, setLoading] = useState(false);
      const [savingData, setSavingData] = useState(false);
      const [commissions, setCommissions] = useState([
            {
                  default: true,
                  fareType: {
                        domestic: {
                              oneStar: 100,
                              twoStar: 0,
                              threeStar: 0,
                              fourStar: 0,
                              fiveStar: 0,
                              specialCategory: 0,
                        },
                        international: {
                              oneStar: 100,
                              twoStar: 0,
                              threeStar: 0,
                              fourStar: 0,
                              fiveStar: 0,
                              specialCategory: 0,
                        },
                  },
            },
      ]);

      const addCommission = () => {
            setCommissions((prev) => [
                  ...prev,
                  {
                        default: false,
                        fareType: {
                              domestic: {
                                    oneStar: 100,
                                    twoStar: 0,
                                    threeStar: 0,
                                    fourStar: 0,
                                    fiveStar: 0,
                                    specialCategory: 0,
                              },
                              international: {
                                    oneStar: 100,
                                    twoStar: 0,
                                    threeStar: 0,
                                    fourStar: 0,
                                    fiveStar: 0,
                                    specialCategory: 0,
                              },
                        },
                  },
            ]);
      };

      const removeCommission = (index) => {
            setCommissions((prev) => prev.filter((_, idx) => idx !== index));
      };

      // Unified handler function for input changes
      const handleInputChange = (
            index,
            field,
            value,
            fareTypeKey,
            fareType
      ) => {
            setCommissions((prev) =>
                  prev.map((comm, idx) => {
                        if (idx === index) {
                              if (fareType && fareTypeKey) {
                                    // Update fareType fields
                                    return {
                                          ...comm,
                                          fareType: {
                                                ...comm.fareType,
                                                [fareType]: {
                                                      ...comm.fareType[
                                                            fareType
                                                      ],
                                                      [fareTypeKey]:
                                                            value || "",
                                                },
                                          },
                                    };
                              } else {
                                    // Update main fields
                                    return { ...comm, [field]: value || "" };
                              }
                        }
                        return comm;
                  })
            );
      };

      const handleDefaultChange = (index) => {
            setCommissions((prev) =>
                  prev.map((comm, idx) => ({
                        ...comm,
                        default: idx === index,
                  }))
            );
      };

      const token = JSON.parse(localStorage.getItem("token"));

      const loadAirlines = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/hotel_commissions/${agentId}/list`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              if (response.data.commission) {
                                    setCommissions(response.data.commission);
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadAirlines();
      }, []);

      const handleCommission = async () => {
            setSavingData(true);

            const data = {
                  commissions: commissions,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/hotel_commissions/${agentId}/update`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setTimeout(() => {
                                    setSavingData(false);
                                    toast.success(response.data.message);
                              }, 3000);
                        }
                  })
                  .catch((error) => {
                        setTimeout(() => {
                              setSavingData(false);
                              console.log(error.message);
                        }, 3000);
                  });
      };

      const handleResetCommission = async () => {
            setResetLoading(true);
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/hotel_commissions/${agentId}/reset`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        setTimeout(() => {
                              setResetLoading(false);
                              if (response.data.result === "success") {
                                    if (response.data.message) {
                                          loadAirlines();
                                          toast.success(response.data.message);
                                    }
                              }
                        }, 3000);
                  })
                  .catch((error) => {
                        setTimeout(() => {
                              setResetLoading(false);
                              console.log(error.message);
                        }, 3000);
                  });
      };

      return (
            <>
                  <Helmet>
                        <title>Edit Hotel Commission </title>
                  </Helmet>
                  <div className="d-flex align-items-center justify-content-between">
                        <Button
                              variant="primary"
                              onClick={addCommission}
                              className="bg-white mb-3 "
                        >
                              Add New
                        </Button>
                        <div className="d-flex gap-3">
                              <Button
                                    as={Link}
                                    to={`/dashboard/agents/${agentId}/show`}
                                    variant="secondary"
                                    className="bg-white mb-3"
                              >
                                    Back
                              </Button>
                              <Button
                                    variant="danger"
                                    className="bg-white mb-3"
                                    onClick={handleResetCommission}
                                    disabled={resetLoading}
                              >
                                    {resetLoading ? <Spinner /> : "Reset"}
                              </Button>
                              <Button
                                    style={{ minWidth: "120px" }}
                                    variant="success"
                                    onClick={handleCommission}
                                    disabled={savingData}
                                    className="mb-3 d-flex bg-white align-items-center justify-content-center"
                              >
                                    {savingData ? <Spinner /> : "Save"}
                              </Button>
                        </div>
                  </div>
                  {!loading ? (
                        commissions.map((item, index) => (
                              <CommissionItem key={index}>
                                    <Row className="g-2">
                                          {/* Default Select */}
                                          <FormSelect
                                                title="Default"
                                                classes={3}
                                                options={[
                                                      {
                                                            title: "Yes",
                                                            id: true,
                                                      },
                                                      {
                                                            title: "No",
                                                            id: false,
                                                      },
                                                ]}
                                                selected={item.default}
                                                setData={(e) =>
                                                      handleDefaultChange(index)
                                                }
                                          />
                                          <Col lg={12}>
                                                <Row className="g-2">
                                                      {/* Domestic Fare Inputs */}
                                                      {Object.entries(
                                                            item.fareType
                                                                  .domestic
                                                      ).map(([key, value]) => (
                                                            <FormInput
                                                                  key={`domestic-${index}-${key}`}
                                                                  title={`Dom ${key}`}
                                                                  classes={2}
                                                                  value={
                                                                        value !==
                                                                        undefined
                                                                              ? value
                                                                              : 0
                                                                  }
                                                                  handleChange={(
                                                                        value
                                                                  ) =>
                                                                        handleInputChange(
                                                                              index,
                                                                              null,
                                                                              value,
                                                                              key,
                                                                              "domestic"
                                                                        )
                                                                  }
                                                            />
                                                      ))}
                                                      {/* International Fare Inputs */}
                                                      {Object.entries(
                                                            item.fareType
                                                                  .international
                                                      ).map(([key, value]) => (
                                                            <FormInput
                                                                  key={`international-${index}-${key}`}
                                                                  title={`Int'l ${key}`}
                                                                  classes={2}
                                                                  value={
                                                                        value !==
                                                                        undefined
                                                                              ? value
                                                                              : 0
                                                                  }
                                                                  handleChange={(
                                                                        value
                                                                  ) =>
                                                                        handleInputChange(
                                                                              index,
                                                                              null,
                                                                              value,
                                                                              key,
                                                                              "international"
                                                                        )
                                                                  }
                                                            />
                                                      ))}
                                                </Row>
                                          </Col>
                                          <Col
                                                lg={12}
                                                className="text-end mt-2"
                                          >
                                                {index !== 0 && (
                                                      <Button
                                                            variant="danger"
                                                            onClick={() =>
                                                                  removeCommission(
                                                                        index
                                                                  )
                                                            }
                                                      >
                                                            Remove
                                                      </Button>
                                                )}
                                          </Col>
                                    </Row>
                              </CommissionItem>
                        ))
                  ) : (
                        <div className="py-5 my-5 text-center">
                              <Spinner />
                        </div>
                  )}
            </>
      );
};

export default HotelCommissionPage;
