import styled from "styled-components";

export const CommissionItem = styled.div`
      background-color: ${(props) => props.theme.white};
      margin-bottom: 2rem;
      padding: 20px;
      border: 1px solid rgba(0, 0, 0, 0.175);

      .css-hlgwow {
            padding-top: 0;
            padding-bottom: 0;
      }

      .css-19bb58m {
            margin: 0;
            padding: 0;
      }

      .css-1xc3v61-indicatorContainer {
            padding: 4px;
      }

      .css-13cymwt-control {
            min-height: 34px;
      }

      input,
      label,
      select {
            font-size: 14px !important;
      }

      label {
            font-weight: 500;
      }
`;
