import { Route } from "react-router-dom";
import Setting from "../profile/Setting";
import PaymentGatewaySetting from "../profile/PaymentGatewaySetting";
import Profile from "../profile/Profile";
import ChangePassword from "../profile/ChangePassword";

export const utilityManage = [
      <Route path="/dashboard/profile" element={<Profile />} />,
      <Route path="/dashboard/settings" element={<Setting />} />,
      <Route
            path="/dashboard/payment-gateway"
            element={<PaymentGatewaySetting />}
      />,
      <Route path="/dashboard/change-password" element={<ChangePassword />} />,
];
