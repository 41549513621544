import React, { useEffect, useState } from "react";
import ProfileLayout from "../ProfileLayout";
import ProfileTitle from "../../components/profile/ProfileTitle";
import { Form, Spinner } from "react-bootstrap";
import { FormButton, FormInput } from "../../components/form";
import axios from "axios";
import toast from "react-hot-toast";

const PaymentGatewaySetting = () => {
      const [loadingData, setLoadingData] = useState(false);
      const [loading, setLoading] = useState(false);
      const [phoneApi, setPhoneApi] = useState("");
      const [merchantId, setMerchantId] = useState("");
      const [phoneSaltKey, setPhoneSaltKey] = useState("");
      const [phoneRefundUrl, setPhoneRefundUrl] = useState("");
      const [phoneStatusUrl, setPhoneStatusUrl] = useState("");
      const token = JSON.parse(localStorage.getItem("token"));

      const loadData = async () => {
            setLoadingData(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/payment_settings`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setPhoneApi(response.data.payment.phonePeApi);
                              setMerchantId(response.data.payment.merchantId);
                              setPhoneSaltKey(
                                    response.data.payment.phonePeSaltKey
                              );
                              setPhoneRefundUrl(
                                    response.data.payment.phonePeRefundUrl
                              );
                              setPhoneStatusUrl(
                                    response.data.payment.phonePeStatusUrl
                              );
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoadingData(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const handleFormSubmit = async (event) => {
            setLoading(true);
            event.preventDefault();

            const data = {
                  merchantId: merchantId,
                  phonePeApi: phoneApi,
                  phonePeSaltKey: phoneSaltKey,
                  phonePeRefundUrl: phoneRefundUrl,
                  phonePeStatusUrl: phoneStatusUrl,
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/payment_settings`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                                    "Content-Type": "multipart/form-data",
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                        }
                        if (response.data.result == "error") {
                              if (response.data.message.merchantId) {
                                    toast.error(
                                          response.data.message.merchantId
                                    );
                              }
                              if (response.data.message.phonePeApi) {
                                    toast.error(
                                          response.data.message.phonePeApi
                                    );
                              }
                              if (response.data.message.phonePeSaltKey) {
                                    toast.error(
                                          response.data.message.phonePeSaltKey
                                    );
                              }
                              if (response.data.message.phonePeRefundUrl) {
                                    toast.error(
                                          response.data.message.phonePeRefundUrl
                                    );
                              }
                              if (response.data.message.phonePeStatusUrl) {
                                    toast.error(
                                          response.data.message.phonePeStatusUrl
                                    );
                              }
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <ProfileLayout>
                        <ProfileTitle title="Payment Gateway Setting" />
                        {!loadingData ? (
                              <Form
                                    className="row g-3"
                                    onSubmit={handleFormSubmit}
                              >
                                    <FormInput
                                          title="Merchant Id"
                                          value={merchantId}
                                          handleChange={setMerchantId}
                                    />
                                    <FormInput
                                          title="Phone Pe Api"
                                          value={phoneApi}
                                          handleChange={setPhoneApi}
                                    />
                                    <FormInput
                                          title="Salt Key"
                                          value={phoneSaltKey}
                                          handleChange={setPhoneSaltKey}
                                    />
                                    <FormInput
                                          title="Refund Url"
                                          value={phoneRefundUrl}
                                          handleChange={setPhoneRefundUrl}
                                    />
                                    <FormInput
                                          title="Status Url"
                                          value={phoneStatusUrl}
                                          handleChange={setPhoneStatusUrl}
                                    />
                                    <div className="col-md-12">
                                          <FormButton
                                                loading={loading}
                                                title="Update"
                                          />
                                    </div>
                              </Form>
                        ) : (
                              <div className="text-center py-5">
                                    <Spinner />
                              </div>
                        )}
                  </ProfileLayout>
            </>
      );
};

export default PaymentGatewaySetting;
