import React from "react";
import { Link } from "react-router-dom";
import { EditIcon } from "../../icons";

const EditButton = ({ link, classes = '', title }) => {
      return (
            <>
                  <Link to={link} className={`text-secondary d-inline-flex gap-2 align-items-center ${classes}`}>
                        <EditIcon />
                        {title || title}
                  </Link>
            </>
      );
};

export default EditButton;
