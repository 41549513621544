import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { Card, Col, Row } from 'react-bootstrap';

import Progress from 'react-progress-2';
import { toast } from 'react-hot-toast';

import CardHeader from '../../components/common/CardHeader';
import AgentListBox from '../../components/backend/AgentListBox';
import { Helmet } from 'react-helmet';

const AgentList = () => {
      const [loading, setLoading] = useState(false);
      const [agents, setAgents] = useState([]);
      const [totalAgents, setTotalAgents] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const token = JSON.parse(localStorage.getItem('token'));
      const [limit, setLimit] = useState(10);
      const [search, setSearch] = useState('');

      const fetchAgents = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/agents?limit=${limit}&search=${search}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`
                              }
                        }
                  )
                  .then((response) => {
                        setAgents(response.data.agents);
                        setTotalAgents(response.data.totalAgents);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            fetchAgents();
      }, [limit, search]);

      async function getData(pageNumber = 1) {
            window.scrollTo(0, 0);
            setLoading(true);
            Progress.show();
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/dashboard/agents?page=${pageNumber}&limit=${limit}&search=${search}`;
            await axios(url, {
                  headers: {
                        Authorization: `Bearer ${token}`
                  }
            })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setAgents(response.data.agents);
                              setTotalAgents(response.data.totalAgents);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                        Progress.hide();
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      }

      return (
            <>
            <Helmet>
                  <title>Agent List</title>
            </Helmet>
                  <Row>
                        <Col lg={12}>
                              <Card>
                                    <CardHeader title="Agent List">
                                          {/* <LinkButton
                                                link="/dashboard/agents/create"
                                                title="Add New"
                                          /> */}
                                    </CardHeader>
                                    <AgentListBox
                                          loading={loading}
                                          agents={agents}
                                          totalAgents={totalAgents}
                                          perPages={perPages}
                                          setLimit={setLimit}
                                          currentPage={currentPage}
                                          getData={getData}
                                          fetchAgents={fetchAgents}
                                          setLoading={setLoading}
                                          setSearch={setSearch}
                                          setAgents={setAgents}
                                    />
                              </Card>
                        </Col>
                  </Row>
            </>
      );
};

export default AgentList;
